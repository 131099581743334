import { roundStatusEnums } from "../RoundStatusEnums.js";

const status = [
  {
    text: "round_status_await_publish",
    value: roundStatusEnums.AWAITING_PUBLISH,
  },
  { text: "round_status_published", value: roundStatusEnums.PUBLISHED },
  { text: "round_status_closed", value: roundStatusEnums.CLOSED },
  { text: "round_status_canceled", value: roundStatusEnums.CANCELED },
];

export const findSelectedStatus = (selectedStatus) => {
  let findStatus = status.find((item) => {
    return item.value === selectedStatus;
  });

  return findStatus.text;
};
